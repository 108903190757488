import moment from "moment-timezone";
import { MESSAGE_TYPES } from "../../Models/Enum";
import { DEVICE_SIZE } from "./BreakPoints";
import uniqid from "uniqid";

export const handleURLClick = (url: any) => {
  window.open(url, "_blank");
};

export const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )}`
    : null;
};

export const extractPreviousBtnConversationId = (
  originalNodeResponses: any[],
  item: any
) => {
  const data_filter: any = originalNodeResponses.filter(function (
    element: any,
    index: number
  ) {
    return element?.response?.some(function (subElement: any, index4: number) {
      return (
        subElement?.type === MESSAGE_TYPES.FLOW && subElement.id === item?.id
      );
    });
  });

  console.log("foundConversationId", data_filter);
  let foundConversationId = data_filter[0]?.conversation_id;

  return foundConversationId;
};

export const stringToRegex = (str: any) => {
  // Main regex
  const main = str?.match(/\/(.+)\/.*/)[1];

  // Regex options
  const options = str?.match(/\/.+\/(.*)/)[1];

  // Compiled regex
  return new RegExp(main, options);
};

export const isEmptyObject = (obj: object) => {
  return Object.keys(obj).length === 0;
};

export const getText = (response: any) => {
  let text = "";
  if (response && response !== null && response.length > 0) {
    const match = response.filter(
      (item: any) => item.type === "text" || item.type === "carousel"
    );
    if (match.length > 0) {
      if (match?.[0]?.value) {
        if (Array.isArray(match?.[0]?.value) && match?.[0]?.value?.length > 0) {
          text = match?.[0]?.value?.[0].text;
        } else {
          text = match?.[0]?.value;
        }
      } else if (match?.[0]?.text) {
        text = match?.[0]?.text;
      } else {
        text = "";
      }
    }
  }
  return text;
};

export const getChatbotWidth = (size?: number) => {
  if (size && size < DEVICE_SIZE.tablet) {
    let temp = size;
    return temp;
  } else {
    return 376;
  }
};

export const getChatbotHeight = (
  deviceWidth?: number,
  deviceHeight?: number
) => {
  if (deviceWidth && deviceHeight && deviceWidth < DEVICE_SIZE.mobileL) {
    let temp = deviceHeight - 30;
    return temp;
  } else {
    return deviceHeight || 0;
  }
};

export const titlize = (str: string) => {
  return str
    ? str
        ?.toLowerCase()
        ?.split(" ")
        ?.map((word) => {
          return word.replace(word[0], word[0].toUpperCase());
        })
        ?.join(" ")
    : "";
};

export const secondsToHms = (value: string) => {
  const sec = parseInt(value, 10);
  let hours: string | number = Math.floor(sec / 3600);
  let minutes: string | number = Math.floor((sec - hours * 3600) / 60);
  let seconds: string | number = sec - hours * 3600 - minutes * 60;
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  if (hours === 0) {
    return +minutes + ":" + seconds; // Return in MM:SS format
  } else {
    return hours + ":" + minutes + ":" + seconds; // Return in HH:MM:SS format
  }
};

export const wrapWithPTags = (text: string): string => {
  return `<p>${text}</p>`;
};

export const checkQuickQuickActionFaqButtonEnable = (data: any[]) => {
  let enabled = false;
  if (data?.length > 0) {
    data?.forEach((item: any, index: number) => {
      if (item?.type === "user-query" && item?.enabled === true) {
        enabled = true;
      }
    });
  }

  return enabled;
};

export const renderTime = (time: string) => {
  let daysDiff = moment().diff(moment(time), "days");
  if (daysDiff < 2) {
    return moment(time).fromNow();
  } else if (daysDiff < 365) {
    return moment(time).format("MMM DD, HH:mm A");
  } else {
    return moment(time).format("MMM DD YYYY, HH:mm A");
  }
};

export const getMessageTime = (datetime: string): string => {
  let messageTime = moment.utc(datetime, "ddd, DD MMM YYYY HH:mm:ss ZZ");

  if (!messageTime.isValid()) {
    messageTime = moment();
  }

  const currentTime = moment();

  const isToday = messageTime.isSame(currentTime, "day");
  const timeFormat = isToday ? "h:mm A" : "MMM D, h:mm A";

  return messageTime.local().format(timeFormat);
};

export const getCurrentUTCTime = (): string => {
  const currentUTCTime = moment.utc().format("ddd, DD MMM YYYY HH:mm:ss");
  return currentUTCTime + " GMT";
};

export const isWithinBusinessHour = (data: any) => {
  // console.log("business hour data", data);

  if (!data || !data?.activeOperatingHours) {
    console.warn("no business time config found");
    return {
      isChatWithInTime: true,
      timelogs: { reason: "no business time config found" },
    };
  }

  const timezoneCurrentDay = moment.utc().format("dddd").toLowerCase();

  if (!data?.[timezoneCurrentDay]?.enabled) {
    console.warn("business is closed for the given day");
    return {
      isChatWithInTime: false,
      timelogs: { reason: "business is closed for the given day" },
    };
  }

  //* business timezone
  const timezone = data?.timezone?.id || "Asia/Kolkata";
  //* Get the UTC offset for the specified timezone
  const utcOffset = moment.tz(timezone).utcOffset();

  let startHours = data?.[timezoneCurrentDay]?.start?.slice(0, 2) || "10";
  let startMinutes = data?.[timezoneCurrentDay]?.start?.slice(3, 5) || "30";
  // if (startHours == '00') {
  //   startHours = startHours.replace(/^.{2}/g, '24')
  // }

  let endHours = data[timezoneCurrentDay]?.end?.slice(0, 2) || "10";
  let endMinutes = data[timezoneCurrentDay]?.end?.slice(3, 5) || "30";
  // if (endHours == '00') {
  //   endHours = endHours.replace(/^.{2}/g, '24')
  // }

  // Set the currentUtcMoment to the desired day, hours, and minutes
  const utcStartTime = moment
    .utc()
    .day(timezoneCurrentDay)
    .hour(startHours)
    .minute(startMinutes)
    .second(0)
    .millisecond(0);

  const utcEndTime = moment
    .utc()
    .day(timezoneCurrentDay)
    .hour(endHours)
    .minute(endMinutes)
    .second(0)
    .millisecond(0);

  // Subtract the UTC offset from utcStartTime and utcEndTime
  const adjustedUtcStartTime = utcStartTime
    .clone()
    .subtract(utcOffset, "minutes");
  const adjustedUtcEndTime = utcEndTime.clone().subtract(utcOffset, "minutes");

  const isBetween =
    moment.utc().isBetween(adjustedUtcStartTime, adjustedUtcEndTime) || false;

  const timelogs = {
    configTimezone: timezone,
    configUTCOffset: utcOffset,
    configStart: startHours + ":" + startMinutes,
    configEnd: endHours + ":" + endMinutes,
    browserCurrentDay: timezoneCurrentDay,
    browserUTC: moment.utc().format(),
    startUTC: adjustedUtcStartTime.format(),
    endUTC: adjustedUtcEndTime.format(),
    isChatWithinBusinessHour: isBetween,
    reason: isBetween ? null : "Business is closed for the given day",
  };

  console.warn(timelogs);

  return { isChatWithInTime: isBetween, timelogs: timelogs };
};

export const renderTextWithLinks = (htmlString: string) => {
  // Regular expression to match URLs
  const regex = /(https?:\/\/[^<>\s]+)/g;
  const parts = htmlString?.split(regex);

  if (!parts) return htmlString;

  return parts
    ?.map((part: any, index: number) => {
      if (part?.match(regex)) {
        return `<a href="${part}" target="_blank" rel="noopener noreferrer">${part}</a>`;
      }
      return part;
    })
    .join("");
};

export const getDefaultAvatar = (text?: string, index: number = 0) => {
  let a = text || "U";
  let result = a?.trim().charAt(index).toUpperCase() || "U";
  if (result === "+" || typeof result === "number") {
    result = "W";
  }
  return result;
};

export async function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export async function generateOutsideHoursMessage(
  message: string,
  sessionId: string,
  socket: any
) {
  return {
    type: "text",
    value: message,
    session_id: sessionId,
    isUser: false,
    socket_id: socket?.id,
    time: getCurrentUTCTime(),
    message_id: uniqid(),
  };
};

export async function generateConnectSocketToAgent(
  message: string,
  sessionId: string,
  socket: any,
  timelogs: any
) {
  return {
    type: "text",
    value: message,
    session_id: sessionId,
    isUser: true,
    socket_id: socket?.id,
    time: getCurrentUTCTime(),
    message_id: uniqid(),
    timelogs: timelogs,
  };
}
