import React from "react";
import uniqid from "uniqid";
import { useAppSelector } from "../../../Store";
import { allMessagesStore } from "../../../Store/Slices/socket/AllMessagesSlice";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import AppTypingIndicatorMessages from "../UtilsComponents/AppTypingIndicatorMessages";

interface Props {
  [otherProps: string]: any;
}
const AppRenderTypingIndicatorForLandingPgaeBot: React.FC<Props> = React.memo(
  () => {
    const { typingStatus } = useAppSelector(allMessagesStore);

    if (!typingStatus) {
      return null;
    }

    return (
      <AppMessageBoxWrapper
        divStyle={{
          boxShadow: "none",
        }}
        userMessage={false}
        key={uniqid()}
      >
        <AppTypingIndicatorMessages />
      </AppMessageBoxWrapper>
    );
  }
);

export default AppRenderTypingIndicatorForLandingPgaeBot;
