import { IconButton } from "@mui/material";
import React from "react";

import { ConversationService } from "../../../Services/Apis";
import { HostWindowCommService } from "../../../Services/IframeHostService/HostWindowCommService";
import { OEvents } from "../../../Services/IframeHostService/models";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  allMessagesStore,
  removeAllMessages,
  setIsSessionIdFromLocalStorage,
} from "../../../Store/Slices/socket/AllMessagesSlice";
import {
  botInfoStore,
  setOpenStatus,
  setToggleReloadWidget,
} from "../../../Store/Slices/socket/BotInfoSlice";
import { socketStatusStore } from "../../../Store/Slices/socket/SocketSlice";

import { PreviewType, SOCKET_CONNECTION_TYPES } from "../../utils/Enum";

import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
interface Props {
}

const AppEndChatFeedback: React.FC<Props> = () => {
  let commService = React.useMemo(() => {
    return HostWindowCommService.getInstance();
  }, []);

  const dispatch = useAppDispatch();

  const { socket } = useAppSelector(socketStatusStore);
  const { sessionId } = useAppSelector(allMessagesStore);
  const { visitorId, botId, widgetPreviewType, toggleReloadWidget, getaHost } =
    useAppSelector(botInfoStore);
  const botHeaders = {
    appid: JSON.stringify({
      botid: botId,
    }),
    visitor_id: visitorId || null,
    preview: widgetPreviewType === PreviewType.getaWidgetPreview ? true : false,
    "GETA-HOST": getaHost,
  };

  const handleRestartBtn = () => {
    let data = {
      session_id: sessionId,
      rating: 5,
      feedback: "",
    };
    //inform the agent
    socket?.emit(SOCKET_CONNECTION_TYPES.CHAT_END_BY_USER, data);
    ConversationService.postChatFeedback(data, botHeaders)
      .then((res: any) => {
        // const data = res?.data;
      })
      .catch((err: any) => {
        console.error("submit error", err);
      })
      .finally(() => {
        dispatch(setOpenStatus(false));
        initiateBotSessionEvent();
      });
  };

  const initiateBotSessionEvent = () => {
    commService.postMessage({
      event_type: OEvents.CHATBOT_SESSION_ID,
      data: null,
    });
    socket?.disconnect();
    dispatch(removeAllMessages());
    dispatch(setIsSessionIdFromLocalStorage(false));
    // setTimeout(() => {
    dispatch(setToggleReloadWidget(!toggleReloadWidget));
    // }, 10);
  };

  return (
    <>
      <IconButton onClick={() => handleRestartBtn()}>
        <RestartAltOutlinedIcon />
      </IconButton>
    </>
  );
};

export default AppEndChatFeedback;
