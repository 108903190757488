import { useEffect, useState } from "react";
import { StyledTypingLandingPageBot } from "../../Styles/StyledComponents";
import { useAppSelector } from "../../../Store";
import { allMessagesStore } from "../../../Store/Slices/socket/AllMessagesSlice";

interface Props {
  userMessage?: any;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const messages = [
  "Just a moment...",
  "Fetching the information...",
  "Hang tight, we're working on it...",
  "Loading your answer...",
  "Please wait, gathering details...",
  "Processing your request...",
  "Hold on, finding the perfect answer for you...",
  "Almost there...",
  "Working on it...",
  "Fetching the best response...",
];

const AppTypingIndicatorMessages: React.FC<Props> = ({ userMessage }) => {
  const { typingStatus } = useAppSelector(allMessagesStore);
  const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(0);

  useEffect(() => {
    if (!typingStatus) return;

    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [typingStatus]);

  return (
    <StyledTypingLandingPageBot userMessage={userMessage}>
      <p className="messages">{messages[currentMessageIndex]}</p>
    </StyledTypingLandingPageBot>
  );
};

export default AppTypingIndicatorMessages;
