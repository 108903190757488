import { IconButton } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { useBotConfigStore } from "../../../Store/Slices/ChatbotSlices/BotConfigSlice";
import {
  allMessagesStore,
  setAllMessages,
} from "../../../Store/Slices/socket/AllMessagesSlice";
import { socketStatusStore } from "../../../Store/Slices/socket/SocketSlice";
import {
  StyledBannerCarouselSlickSlider,
  StyledBannerCarouselWrapper,
  StyledReactVideoPlayer,
} from "../../Styles/StyledComponents";
import { SOCKET_CONNECTION_TYPES } from "../../utils/Enum";
import { AppMaterialIcons } from "../Icons";
import { botInfoStore } from "../../../Store/Slices/socket/BotInfoSlice";

interface Props {}

const AppRenderBannerCarousel: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { sessionId, allMessages } = useAppSelector(allMessagesStore);
  const { socket } = useAppSelector(socketStatusStore);
  const { botConfig } = useSelector(useBotConfigStore);
  const [data, setData] = useState<any[]>([]);
  const { isBotSetToFullScreen } = useSelector(botInfoStore);
  useEffect(() => {
    if (
      botConfig?.config?.banner_carousel?.enabled &&
      botConfig?.config?.banner_carousel?.data
    ) {
      setData(botConfig?.config?.banner_carousel?.data);
    }
  }, [botConfig]);

  const handleCardClick = (url: any) => {
    window.open(url, "_blank", "noreferrer");
  };

  const handleOptionBtnClick = async (item: any) => {
    let data = {
      type: "text",
      value:
        item?.journey?.sub_flow?.response?.[0]?.value ||
        item?.journey?.main_flow?.name,
      journey: {
        nodeId: item?.journey?.sub_flow?.nodeId,
        qid: item?.journey?.sub_flow?.qid,
        stage: item?.journey?.sub_flow?.stage,
        displayOrder: item?.journey?.sub_flow?.displayOrder,
      },
      session_id: sessionId,
      isUser: true,
    };

    dispatch(setAllMessages([data]));
    if (socket?.connected) {
      socket?.emit(SOCKET_CONNECTION_TYPES.BOT_CONVERSATION, data);
    }
  };

  if (
    botConfig?.config?.banner_carousel?.data?.length < 1 ||
    !botConfig?.config?.banner_carousel?.enabled ||
    allMessages?.length < 1 ||
    allMessages.find((item: any) => item?.isUser === true)
  ) {
    return null;
  }

  return (
    <StyledBannerCarouselWrapper isFullScreen={isBotSetToFullScreen}>
      <StyledBannerCarouselSlickSlider
        isFullScreen={isBotSetToFullScreen}
        {...settings}
      >
        {data?.length &&
          data?.map((item: any, index: number) => {
            return (
              <div
                className="sliderSingleItem"
                key={index.toString()}
                onClick={() =>
                  item?.View_options !== "Video" &&
                  Object.keys(item?.journey)?.length &&
                  item?.position !== "Add Link"
                    ? handleOptionBtnClick(item)
                    : item?.View_options !== "Video"
                    ? handleCardClick(item?.banner_url)
                    : {}
                }
              >
                {item?.video && item?.View_options === "Video" ? (
                  <StyledReactVideoPlayer
                    isFullScreen={isBotSetToFullScreen}
                    url={item?.video}
                    width={"100%"}
                    controls={true}
                    light={true}
                    onClickPreview={(event: any) => {
                      handleCardClick(item?.video);
                    }}
                    onReady={(event: any) => {
                      handleCardClick(item?.video);
                    }}
                  />
                ) : (
                  <img src={item?.image?.source} alt="" />
                )}
              </div>
            );
          })}
      </StyledBannerCarouselSlickSlider>
    </StyledBannerCarouselWrapper>
  );
};

export default memo(AppRenderBannerCarousel);

const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <IconButton onClick={onClick} className={className} style={{ ...style }}>
      <AppMaterialIcons iconName={"ChevronRight"} />
    </IconButton>
  );
};

const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <IconButton onClick={onClick} className={className} style={{ ...style }}>
      <AppMaterialIcons iconName={"ChevronLeft"} />
    </IconButton>
  );
};

const settings = {
  className: "slickSlider",
  adaptiveHeight: true,
  lazyLoad: true,
  fade: true,
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  // autoplaySpeed: 2000,
  // pauseOnHover: true,
  swipeToSlide: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,

  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
};
