import { AxiosResponse } from "axios";
import { PreviewType } from "../../Models/Enum";
import { conversationAxios } from "../../Utils/Axios";
import { getHttpService } from "../HttpService";



import {
  CH_BOT_ENDPOINTS
} from "./Endpoints";



export class ConversationService {
  static httpService = getHttpService(conversationAxios);
  static resendOtp = (config = {}) => {
    return ConversationService.httpService.post(CH_BOT_ENDPOINTS.RESENDOTP, {}, null, config);
  };
  static uploadMedia = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post(CH_BOT_ENDPOINTS.ATTACHMENT, queryData, {}, config);
  };
  static getWelcome = (queryData = {}, config = {}, widgetPreviewType: string | null | undefined = null) => {
    return ConversationService.httpService.get(widgetPreviewType === PreviewType.getaWidgetPreview ? CH_BOT_ENDPOINTS.PREVIEW_QUERY : CH_BOT_ENDPOINTS.QUERY, queryData, config);
  };

  static postUserResponse = (queryData = {}, config = {}, widgetPreviewType: string | null | undefined = null) => {
    return ConversationService.httpService.post(widgetPreviewType === PreviewType.getaWidgetPreview ? CH_BOT_ENDPOINTS.PREVIEW_QUERY : CH_BOT_ENDPOINTS.QUERY, queryData, {}, config);
  };

  static getHistory = (queryData = {}, config = {}) => {
    return ConversationService.httpService.get(CH_BOT_ENDPOINTS.HISTORY, queryData, config);
  };

  static postHistory = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post(CH_BOT_ENDPOINTS.HISTORY, queryData, {}, config);
  };

  static postVisitorId = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post(CH_BOT_ENDPOINTS.SAVE_VISITOR_ID, queryData, {}, config);
  };

  static startLiveAgent = (queryData = {}, config = {}) => {
    return ConversationService.httpService.get(CH_BOT_ENDPOINTS.LIVE_CHAT, queryData, config);
  };

  static switchLanguageGetWelcome = (languageKey: string, queryData = {}, config = {}) => {
    return ConversationService.httpService.get(CH_BOT_ENDPOINTS.SWITCH_LANGUAGE, queryData, config, [languageKey]);
  };

  static postSearchFaqVariations = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post(CH_BOT_ENDPOINTS.SEARCH_FAQ_VARIATIONS, queryData, {}, config);
  };
  static postLeadOutsideBusinessHours = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post(CH_BOT_ENDPOINTS.LEAD_OUTSIDE_BUSINESS_HOURS, queryData, {}, config);
  };

  static postChatFeedback = (queryData: any ={}, config = {}): Promise<AxiosResponse<any>> => {
    return ConversationService.httpService.post(CH_BOT_ENDPOINTS.SUBMIT_CHAT_FEEDBACK, queryData, {}, config );
  };










  //new socket api
  static getMessagesDirectLivechat = (queryData = {}, config = {}) => {
    const url = CH_BOT_ENDPOINTS.QUERY_DIRECT_LIVECHAT;
    return ConversationService.httpService.get(url, queryData, config);
  };
  static getMessages = (queryData = {}, config = {}, widgetPreviewType: string | null | undefined = null) => {
    const url = widgetPreviewType === PreviewType.getaWidgetPreview ? CH_BOT_ENDPOINTS.PREVIEW_QUERY : CH_BOT_ENDPOINTS.QUERY;
    return ConversationService.httpService.get(url, queryData, config);
  };

  static postMessages = (queryData = {}, config = {}, widgetPreviewType: string | null | undefined = null) => {
    const url = widgetPreviewType === PreviewType.getaWidgetPreview ? CH_BOT_ENDPOINTS.PREVIEW_QUERY : CH_BOT_ENDPOINTS.QUERY;
    return ConversationService.httpService.post(url, queryData, {},config);
  };



  static getHistoryMessages = (queryData = {}, config = {}) => {
    return ConversationService.httpService.get(CH_BOT_ENDPOINTS.HISTORY, queryData, config);
  };



}
