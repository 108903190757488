import { LoadingButton } from "@mui/lab";
import {
  Button,
  Fab,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { m, motion } from "framer-motion";
import "react-phone-input-2/lib/style.css";
import ReactPlayer from "react-player";
import styled, { css, keyframes } from "styled-components";
import { getChatbotHeight, getChatbotWidth } from "../../Utils";
import {
  TOOLTIP_POSITIONS_OPTIONS,
  WIDGET_POSITIONS_OPTIONS,
} from "../utils/Enum";
import { theme } from "../../Customization/Theme";
import { DEVICE_BREAKPOINT } from "../utils/BreakPoints";
import ScrollToBottom from "react-scroll-to-bottom";

interface StyledProps {
  userMessage?: any;
  tooltipPosition?: any;
  isTooltipVisible?: any;
  WIDGET_POSITION?: any;
  variant?: any;
  enableuserinput?: number;
  chatopened?: any;
  devicewidth?: any;
  deviceheight?: any;
  jumpPosition?: any;
  theme?: any;
  selected?: any;
  isInvalid?: any;
  disabled?: boolean;
  ref?: any;
  isFullScreen?: boolean;
}


interface InputRef {
  ref?: any;
  innerRef?: any;
}
export const WIDGET_ICON_HEIGHT = 62;
export const WIDGET_MIN_HEIGHT_WITH_TOP_TOOLTIP = 870;

export const WIDGET_WRAPPER_MIN_WIDTH_WITH_TOOLTIP = 860;

export const WIDGET_MIN_HEIGHT_WITHOUT_TOP_TOOLTIP = 110;
export const WIDGET_WRAPPER_MIN_WIDTH_WITHOUT_TOOLTIP = 130;
// export const WIDGET_MIN_HEIGHT_WITHOUT_TOP_TOOLTIP = 110;
// export const WIDGET_MIN_HEIGHT_WITH_TOP_TOOLTIP = 170;

// export const WIDGET_WRAPPER_MIN_WIDTH_WITH_TOOLTIP = 260;
// export const WIDGET_WRAPPER_MIN_WIDTH_WITHOUT_TOOLTIP = 130;

export const slideUp = (W: any, H: any) => keyframes`
  0%{
    opacity: 0;
    height: 0px;
    /* width: 0px; */
  }

  50%{
    /* width: ${W / 2}px; */
    height: ${H / 2}px;
    opacity: 0.5;
   
  }
  100%{
    opacity: 1;
    /* width: ${W}px; */
    height: ${H}px;
  }
`;
export const slideDown = (W: any, H: any) => keyframes`
  0%{
    opacity: 1;
    /* width: ${W}px; */
    height: ${H}px;
  }

  50%{
    height: ${H / 2}px;
    /* width: ${W / 2}px; */
    opacity: 0.1;
   
  }
  100%{
    opacity: 0;
    height: 0px;
    /* width:  0px; */
  }
`;
export const containerSlideUp = (W: any, H: any) => keyframes`
0%{
  height: ${WIDGET_MIN_HEIGHT_WITH_TOP_TOOLTIP}px;
  /* width: auto; */
}

50%{
  /* width: ${W / 2}px; */
  height: ${H / 2}px;
}
100%{
  /* width: ${W}px; */
  height: calc(${H}px + 1rem);
}
`;
export const containerSlideDown = (W: any, H: any) => keyframes`
  0%{
    /* width: auto; */
    height: calc(${H}px + 1rem);  
  }

  50%{
    height: ${H / 2}px;
    /* width: auto; */
  }
  100%{
    height: 0px;
  }
`;

const getSlideUpAnimationValue = (
  devicewidth: number,
  deviceheight: number
) => {
  return css`
    animation: ${slideUp(
    getChatbotWidth(devicewidth),
    getChatbotHeight(devicewidth, deviceheight)
  )}
      0.2s forwards linear;
  `;
};
const getSlideDownAnimationValue = (
  devicewidth: number,
  deviceheight: number
) => {
  return css`
    animation: ${slideDown(
    getChatbotWidth(devicewidth),
    getChatbotHeight(devicewidth, deviceheight)
  )}
      0.2s forwards linear;
  `;
};
const getContainerSlideUpAnimationValue = (
  devicewidth: number,
  deviceheight: number
) => {
  return css`
    animation: ${containerSlideUp(
    getChatbotWidth(devicewidth),
    getChatbotHeight(devicewidth, deviceheight)
  )}
      0.1s forwards linear;
  `;
};
const getContainerSlideDownAnimationValue = (
  devicewidth: number,
  deviceheight: number
) => {
  return css`
    animation: ${containerSlideDown(
    getChatbotWidth(devicewidth),
    getChatbotHeight(devicewidth, deviceheight)
  )}
      0.1s forwards linear;
  `;
};

const getLeftRightPosition = (WIDGET_POSITION: any) => {
  if (WIDGET_POSITION === WIDGET_POSITIONS_OPTIONS.LEFT) {
    return css`
      left: 10px;
    `;
  } else {
    return css`
      right: 10px;
    `;
  }
};

export const StyledBotContainer = styled.div<StyledProps>`
  && {
    ${({ WIDGET_POSITION }) => getLeftRightPosition(WIDGET_POSITION)};
    position: static;
    z-index: 9999;
    bottom: 10px;
    // margin: 0 auto;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: ${({ WIDGET_POSITION }) =>
    WIDGET_POSITION === WIDGET_POSITIONS_OPTIONS.LEFT ? "left" : "right"};
    align-items: flex-start;

    min-height: ${WIDGET_MIN_HEIGHT_WITHOUT_TOP_TOOLTIP}px;
    min-width: ${WIDGET_WRAPPER_MIN_WIDTH_WITHOUT_TOOLTIP} px;
    width: ${({ isFullScreen, devicewidth, chatopened,  deviceheight }) => {
      return isFullScreen ? '100%' : `${getChatbotWidth(devicewidth)}px`}
    };

    ${({ chatopened, devicewidth, deviceheight }) =>
    chatopened
      ? getContainerSlideUpAnimationValue(devicewidth, deviceheight)
      : getContainerSlideDownAnimationValue(devicewidth, deviceheight)};

    .closeTooltipBtn {
      display: none !important;
    }

    &:hover {
      .closeTooltipBtn {
        display: flex !important;
      }
    }

    //disable user copy and select
    /* -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
  }
`;

export const StyledChatUIBoxWrap = styled.div<StyledProps>`
  && {
    border-top: 10px solid
      ${({ theme }) => {
    return theme?.components?.frame?.border?.color;
  }};
    border-right: 2px solid
      ${({ theme }) => {
    return theme?.components?.frame?.border?.color;
  }};
    border-left: 2px solid
      ${({ theme }) => {
    return theme?.components?.frame?.border?.color;
  }};
    border-bottom: 2px solid
      ${({ theme }) => {
    return theme?.components?.frame?.border?.color;
  }};
    border-radius: 8px;
    width: ${({ isFullScreen, chatopened, devicewidth, deviceheight }) =>  {
      return isFullScreen ? '100%' : `${getChatbotWidth(devicewidth)}px`}
    };

    ${({ chatopened, devicewidth, deviceheight }) =>
    chatopened
      ? getSlideUpAnimationValue(devicewidth, deviceheight)
      : getSlideDownAnimationValue(devicewidth, deviceheight)};
    background-color: ${({ theme }) => {
    return theme?.components?.chat_wall.background.color;
  }};
    // box-shadow: 0px 4px 1rem rgba(0, 0, 0, 0.16);
    /* display: flex; */
    display: ${({ chatopened }) => (chatopened ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: flex-end;
    z-index: 9999;

    overflow: ${({ chatopened }) => (!chatopened ? "clip" : "unset")};
    position: absolute;
    z-index: 1;
    bottom: ${({ chatopened, devicewidth, deviceheight }) =>
    chatopened ? "0" : "-100%"};
    transition: bottom 0.2s ease-in-out;
    min-height: calc(100%);
    // left: 1px;
    max-height: 1rem;
  }
`;

export const bounce = keyframes`

  0%{
    margin-right: -60px;
    opacity: 0.00;
  }

  50%{
    margin-right: -50px;
    opacity: 0.0;
  }
  100%{
    margin-right: 0px;
    opacity: 1;
  }
`;
export const QuickChatRightShowAnimation = keyframes`

  0%{
    right: -3rem;
    opacity: 0.00;
     }

  50%{
    right: -2.5rem;
    opacity: 0.0;
  }
  100%{
    right: 0rem;
    opacity: 1;
  }
`;
export const QuickChatLeftShowAnimation = keyframes`

  0%{
    left: -3rem;
    opacity: 0.00;
     }

  50%{
    left: -2.5rem;
    opacity: 0.0;
  }
  100%{
    left: 0rem;
    opacity: 1;
  }
`;

export const QuickChatHideAnimation = keyframes`
  0%{
    bottom: 0rem;
    opacity: 1;
     }

  50%{
    bottom: -2.5rem;
    opacity: 0.2;
  }
  100%{
    bottom: -3rem;
    opacity: 0;
  }
`;
export const tooltipAnimation = keyframes`

  0%{
   width: 20px;
     }

  50%{
    width: 100px;
  }
  100%{
    width: 304px;
  }
`;

export const StyledFloatingButtonWrap = styled.div<StyledProps>`
  && {
    position: absolute;

    bottom: 0px;
    ${({ WIDGET_POSITION }) => getLeftRightPosition(WIDGET_POSITION)};

    /* padding-left: 8px;
    padding-right: 8px; */
    padding-bottom: 8px;
    // max-width: 280px;
    // display: flex;
    display: ${({ chatopened }) => (chatopened ? "none" : "flex")};
    flex-direction: ${({ tooltipPosition, WIDGET_POSITION }) =>
    tooltipPosition === TOOLTIP_POSITIONS_OPTIONS.TOP
      ? "column"
      : WIDGET_POSITION === WIDGET_POSITIONS_OPTIONS.LEFT
        ? "row-reverse"
        : "row"};
    justify-content: flex-end !important;
    align-items: ${({ tooltipPosition, WIDGET_POSITION }) =>
    WIDGET_POSITION === WIDGET_POSITIONS_OPTIONS.LEFT &&
      tooltipPosition === TOOLTIP_POSITIONS_OPTIONS.TOP
      ? "flex-start"
      : "flex-end"};
    gap: 8px;


    .QuickChatIcon{
      order:${({ WIDGET_POSITION }: any) =>
    WIDGET_POSITION === WIDGET_POSITIONS_OPTIONS.LEFT
      ? 1
      : 2} !important;
    }

    .tooltip-quick-chat-side-wrapper {
      max-width: ${({ isTooltipVisible }) =>
    isTooltipVisible
      ? WIDGET_WRAPPER_MIN_WIDTH_WITH_TOOLTIP
      : WIDGET_WRAPPER_MIN_WIDTH_WITHOUT_TOOLTIP};

      display: flex;
      flex-direction: ${({ WIDGET_POSITION }) =>
    WIDGET_POSITION === WIDGET_POSITIONS_OPTIONS.LEFT
      ? "row-reverse"
      : "row"};
      gap: 0.3rem;
      align-items: center;

      .ChatBotTooltip {
        cursor: pointer;
        position: relative;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${theme.palette.default.black};
        min-width: 180px;
        text-overflow: ellipsis;
        padding: 6px 10px 6px 12px;
        gap: 8px;
        background: ${theme.palette.default.white};
        z-index: 10000;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
        border-radius: 6px;
        small {
          font-size: 14px;
          font-weight: 400;
          font-family: ${({ theme }) => theme?.font?.primary.family};
          line-height: 16px;
        }

        .closeTooltipBtn,
        .MuiIconButton-root {
          position: absolute;
          right: 0;
          top: 0;
          transform: translateY(-100%);
          z-index: 999999999999999;
          color: #5a5959;
          font-size: 29px !important;
          width: 26px;
          height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }

    .tooltip-quick-chat-top-wrapper {
      /* background-color: brown; */
      display: flex;
      max-width: ${({ isTooltipVisible }) =>
    isTooltipVisible
      ? WIDGET_WRAPPER_MIN_WIDTH_WITH_TOOLTIP
      : WIDGET_WRAPPER_MIN_WIDTH_WITHOUT_TOOLTIP};
      flex-direction: column;
      gap: 0.8rem;

      align-items: ${({ WIDGET_POSITION }) =>
    WIDGET_POSITION === WIDGET_POSITIONS_OPTIONS.LEFT ? "start" : "end"};

      .ChatBotTooltip {
        cursor: pointer;
        position: relative;
        background-color: white;
        display: flex;

        color: ${theme.palette.default.black};
        max-width: 160px;
        min-width: 160px;
        width: ${WIDGET_WRAPPER_MIN_WIDTH_WITHOUT_TOOLTIP}px;
        max-height: 70px;
        min-height: 40px;
        text-overflow: ellipsis;
        gap: 8px;
        border-radius: 6px;
        z-index: 10000;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
        padding: 4px 5px 4px 5px;
        small {
          font-size: 14px;
          font-weight: 400;
          font-family: ${({ theme }) => theme?.font?.primary.family};
          overflow: hidden;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .closeTooltipBtn,
        .MuiIconButton-root {
          position: absolute;
          top: 0;
          right: 0%;
          z-index: 999999999999999;
          color: #5a5959;
          font-size: 29px !important;
          width: 26px;
          height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;
          transform: translateY(-100%);
        }
      }
    }
  }
`;

export const StyledCalloutMessageCard = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-end;
    margin-bottom: 4px;
    width: max-content;
    max-width: 280px;
    min-width: 170px;
    .MuiCard-root {
      padding: 12px 12px;
      // max-width: 100%;
      // min-width: 190px;
      // max-width: 280px;
      margin: 0px 0px 0px;
      word-break: break-word;
      text-align: initial;
      // width: fit-content;
      width: auto;
      position: relative;
      transition: all 0.4s ease 0s;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px 0px;
      border-radius: 4px;
      border: 1px solid rgb(238, 238, 238);

      & .MuiCardHeader-root {
        padding: 4px 0px 2px 0px;

        & .MuiCardHeader-avatar {
          margin-right: 8px;
        }
        & .MuiAvatar-root {
          width: 20px;
          height: 20px;
          text-align: center;
          font-size: 13px;
        }

        & .MuiCardHeader-content {
          & .MuiTypography-root {
            font-size: 13px;
            font-weight: 500;
            font-family: Poppins;
            letter-spacing: 0.5px;
            color: ${theme.palette.default.darkGrey};
          }
        }
      }

      & .cardImageContainer {
        margin-top: 6px;
        margin-bottom: 6px;
        width: 100%;
        height: 140px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .cardImage {
          border-radius: 4px;
          object-fit: fill;
          max-width: 240px;
          min-width: 240px;
          height: 140px;
          width: 100%;
          aspect-ratio: auto 240 / 140;
          overflow: clip;
        }
      }

      & .MuiCardContent-root {
        padding: 4px 0px 2px 0px;

        .MuiTypography-root {
          font-size: 13.2px;
          font-weight: 400;
          font-family: Poppins;
          color: ${theme.palette.default.text};
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .tooltipActionBox {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .MuiIconButton-root {
        background-color: rgba(0, 0, 0, 0.04);

        width: 22px;
        height: 22px;
        svg {
          font-size: 1rem;
        }
      }
    }
  }
`;

export const chatIConUp = keyframes`

  0%{
    opacity: 0;
  }

  50%{
    opacity: 0;
  }
  100%{

    opacity: 1;
  }
`;
export const PulseAnimation = keyframes`
  0%{
      width: 100%;   
      height: 100%;
      opacity: 0.7;
  }
  100%{
      width: 200%;   
      height: 200%;
      opacity: 0;
  }
`;

export const bounceAnimation = keyframes`
from {
 transform: translate3d(0, -6px, 0);
}
to {
  transform: translate3d(0, 0px, 0);
  }
`;
export const rotateAnimation = keyframes`
0% {
  transform: rotate(-15deg);
}
100% {
  transform: rotate(0deg);
  }
`;

export const QuickChatIcon = styled(Fab) <StyledProps>`
  && {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
    animation: ${bounceAnimation} 0.8s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
    animation-iteration-count: infinite;
    animation-delay: 5s;

    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    min-width: 60px;
    min-height: 60px;
    width: 60px;
    height: 60px;
    border: 2px solid
      ${({ theme }) => {
    return theme?.components?.frame?.border?.color;
  }};
    overflow: hidden;

    background-color: ${({ theme }) => {
    return theme?.components?.frame?.border?.color;
  }};

    .MuiSvgIcon-root {
      fill: #fff;
    }

    &:hover {
      background-color: ${({ theme }) => {
    return theme?.components?.frame?.border?.color;
  }};
    }

    .styledChatLogo {
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
      overflow: hidden;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      box-shadow: 2px 8px 8px rgba(0, 0, 0, 0.2);
      object-position: top center;
      animation: ${rotateAnimation} 0.25s linear 1 normal forwards;
    }
  }
`;
export const StyledFab = styled(Fab) <StyledProps>`
  && {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
    z-index: 92323123;
    animation: ${bounceAnimation} 0.8s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
    animation-iteration-count: infinite;
    animation-delay: 5s;

    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 50%;
    width: 60px;
    height: 60px;

    cursor: pointer;
    z-index: 8888;
    img {
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
      overflow: hidden;
      width: 64px;
      border: 2px solid
        ${({ theme }) => theme?.components?.primary_color_scheme["300"]};
      height: ${WIDGET_ICON_HEIGHT}px;
      border-radius: 50%;
      box-shadow: 2px 8px 8px rgba(0, 0, 0, 0.2);
      object-position: top center;

      animation: ${rotateAnimation} 0.5s linear 0.3s 1 normal forwards;
    }
  }
`;

export const QuickCloseIcon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.palette.default.border};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 989;
  img {
    object-fit: cover;
    width: 40px;
    height: 40px;
  }
`;

export const StyledWidgetHeader = styled.div<StyledProps>`
  && {
    background-color: #fff;
    height: 74px;
    max-height: 74px;
    min-height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px 0 8px;
    // border-radius: 12px 12px 0px 0px;
    position: relative;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
    // z-index: 1;
    svg {
      fill: ${({ theme }) => {
    return theme?.components?.header?.icon?.color;
  }};
    }
  }
`;

export const WidgetHeaderBrand = styled(Box)`
  && {
    height: 40px;
    flex: 0.9;
    display: flex;
    align-items: center;
    gap: 12px;

    font-family: ${({ theme }) => {
    return theme?.components?.header.text.family;
  }};
    color: ${({ theme }) => {
    return theme?.components?.header.text.color;
  }};

    //disable user copy and select
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      object-fit: cover;
      border: 1px solid;
    }

    .titleBox {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const WidgetHeaderBrandTitle = styled(Typography)`
  && {
    font-family: ${({ theme }) => {
    return theme?.components?.header.text.family;
  }};
    color: ${({ theme }) => {
    return "#282828";

    // return theme?.components?.header.text.color;
  }};
    font-weight: 500;
    font-size: 1rem;
    line-height: 18px;
    cursor: default;
    @media ${DEVICE_BREAKPOINT.mobileL} {
      font-size: 14px;
    }
  }
`;
export const WidgetHeaderStatus = styled(Typography)`
  && {
    font-family: ${({ theme }) => {
    return theme?.components?.header.text.family;
  }};
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => {
    return theme?.components?.header.text.color;
  }};
    cursor: default;
  }
`;

export const WidgetHeaderRight = styled(Box)`
  && {
    color: ${({ theme }) => {
    return theme?.components?.header.text.color;
  }};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;
    position: relative;
    .MuiIconButton-root {
      padding: 6px !important;
    }
  }
`;

export const WidgetHeaderLogo = styled.img`
  && {
    height: 40px;
    width: 40px;
  }
`;

export const WidgetMainContent = styled(Box) <StyledProps>`
  && {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    height: 100%;
    padding: 0 !important;

    -ms-overflow-style: none;
    scrollbar-width: none;

    background-color: ${({ theme }) => {
    return theme?.components?.chat_wall.background.color;
  }};

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }

    .css-1oexdke.css-1oexdke {
      padding: 0 !important;
    }

    @media ${DEVICE_BREAKPOINT.mobileL} {
      height: 100%;
    }
  }
`;
// export const WidgetMainChat = styled(Box)<StyledProps>`
export const WidgetMainChat = styled(ScrollToBottom) <StyledProps>`
  && {
    position: relative;
      height: 100%;
    overflow-y: overlay;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    // border: 4px solid green;
    padding: 12px 5px 12px 12px;

    .styledList {
      padding: 2px 7px 2px 4px;
       overflow-y: overlay;
    overflow-x: hidden;
      ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: #cacaca;
      }
      ::-webkit-scrollbar {
        width: 4px;
        background-color: #dfdfdf;
      }
    }

    .scrollToBottomDefaultButton {
      display: none;
      border-radius: 50%;
      border-width: 0;
      cursor: pointer;
      position: absolute;
      z-index: 1;
      left: 50%;
      bottom: 16px;
      transform: translate(-50%, -50%);
      margin: 0 auto;
      width: 28px;

      &:before {
        content: "▼";
        color: ${theme.palette.default.darkGrey};
      }
    }

    /* -ms-overflow-style: none; IE and Edge */
    /* scrollbar-width: none; Firefox */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    /* display: none; */

    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const StyledList = styled(List)`
  && {
    margin: 2px 0 0;
    padding: 2px 0;
  }
`;
export const StyledListItem = styled(ListItem) <StyledProps>`
  && {
    display: flex;
    align-items: flex-end;
    justify-content: ${(props) => (props.userMessage ? "right" : "left")};
    padding: 4px 0;
    margin: 8px 0;
    gap: 10px;
  }

  img {
    display: ${(props) => (props.userMessage ? "none" : "flex")};
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid
      ${(props: any) =>
    props.userMessage
      ? theme.palette.default.text
      : theme.palette.secondary.main};
    box-shadow: 0px 1px 4px #999;
  }
`;

export const MessageContainer = styled.div<StyledProps>`
  && {
    max-width: 75%;

    word-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: ${(props) => (props.userMessage ? "right" : "left")};
    justify-content: ${(props) => (props.userMessage ? "right" : "left")};
    /* padding: 4px 0; */
    overflow: hidden;
    color: ${({ userMessage, theme }) =>
    userMessage
      ? theme?.components?.user_message?.text?.color
      : theme?.components?.bot_message?.text?.color};
    animation-delay: 2s;
    position: relative;

    &:after {
      ${({ userMessage, theme }) =>
    userMessage
      ? `
        right: -1px;
        bottom: 6px;
        transform: rotate(180deg);
        `
      : `
        left: -1px;
        bottom: 6px;
        transform: rotate(180deg);
        `}
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      border: 10px solid;
      border-color: ${({ userMessage, theme }) =>
    userMessage
      ? theme?.components?.user_message?.background?.color
      : theme?.components?.bot_message?.background?.color}
        transparent transparent transparent;
    }
  }
`;

export const MessageMediaRowAttachment = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: ${theme.palette.default.lightGrey};
  font-size: 14px;
  border-radius: 5px;
  svg {
    margin-right: 5px;
    max-width: 15px;
  }
`;
export const MessageRow = styled.div<StyledProps>`
  && {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: ${(props) =>
    props.userMessage ? "flex-end" : "flex-start"};
    gap: 4px;
    margin: 6px 0;
    /* margin-bottom: 10px; */
    word-wrap: break-word;
    align-items: center;
    //disable user copy and select
    /* -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    .avtar img,
    img {
      display: flex;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
      border: 1px solid
        ${({ theme }) => {
    return theme?.components?.header.background.color;
  }};
      box-shadow: 0px 1px 4px #999;
    }
  }
`;

export const MessageContainerText = styled.div<StyledProps>`
  && {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: ${({ userMessage, theme }) =>
    userMessage ? "2px 5px 2px 4px" : "2px 4px"};
    font-family: ${({ userMessage, theme }) =>
    userMessage
      ? theme?.components?.user_message?.text?.family
      : theme?.components?.bot_message?.text?.family};
    font-weight: 400;
    font-size: ${({ userMessage, theme }) =>
    userMessage
      ? theme?.components?.user_message?.text?.size
      : theme?.components?.bot_message?.text?.size};
    line-height: 24px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    padding: 8px;
    border-radius: ${({ userMessage, theme }) =>
    userMessage ? "10px 0px 0px 10px" : "0px 10px 10px 0px"};
    background-color: ${({ userMessage, theme }) =>
    userMessage
      ? theme?.components?.user_message?.background?.color
      : theme?.components?.bot_message?.background?.color};
    /* &:after {
      ${({ userMessage, theme }) =>
    userMessage
      ? `
        right: -1px;
        bottom: 6px;
        transform: rotate(180deg);
        `
      : `
        left: -1px;
        bottom: 6px;
        transform: rotate(180deg);
        `}
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      border: 10px solid;
      border-color: ${({ userMessage, theme }) =>
    userMessage
      ? theme?.components?.user_message?.background?.color
      : theme?.components?.bot_message?.background?.color}
        transparent transparent transparent;
    } */
    p {
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 15px 4px 2px;
      font-family: ${({ userMessage, theme }) =>
    userMessage
      ? theme?.components?.user_message?.text?.family
      : theme?.components?.bot_message?.text?.family};
      font-weight: 400;
      font-size: ${({ userMessage, theme }) =>
    userMessage
      ? theme?.components?.user_message?.text?.size
      : theme?.components?.bot_message?.text?.size};
      line-height: 24px;
      &:first-child {
        margin-top: 0;
      }
    }
    a {
      color: blue;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: ${({ theme }) => theme?.palette?.primary.dark};
      }
      &:active {
        color: blue;
      }
    }

    @media ${DEVICE_BREAKPOINT.mobileL} {
      font-size: 14px;
    }
  }
`;

export const MessageTimeStampBox = styled.div<StyledProps>`
  && {
    display: flex;
    width: 100%;
    justify-content: ${(props) => (props.userMessage ? "right" : "left")};
    word-wrap: break-word;
    display: flex;
    flex-direction: row;
    font-family: ${({ theme }) => theme?.typography.fontFamily};
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  }
`;

export const MessageMediaRow = styled.div<StyledProps>`
  && {
    background-color: ${({ theme }) => theme?.palette?.secondary.main};
    background-color: transparent;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    gap: 6px;
    word-wrap: break-word;
    width: 75%;
    max-width: 75%;

    img {
      width: 100%;
      height: 11rem;
      object-fit: cover;
      border: 1px solid ${theme.palette.default.border};
      border-radius: 4px;
    }
  }
`;

export const StyledIframeVideoPlayer = styled.iframe`
  && {
    overflow: hidden !important;
    border-radius: 6px;
    width: 100%;
    object-fit: contain;
    min-height: 10rem;
    min-height: 170px;
    max-height: 12rem;
    background-color: ${({ theme }) => theme?.palette?.default?.text};
  }
`;
export const StyledReactVideoPlayer = styled(ReactPlayer)`
  && {
    overflow: hidden !important;
    border-radius: 6px;
    width: 100%;
    object-fit: contain;
    min-height: 10rem;
    min-height: 170px;
    max-height: 12rem;
    background-color: ${({ theme }) => theme?.palette?.default?.text};
  }
`;

export const MessageButtonRow = styled(Box)`
  && {
    background-color: ${theme.palette.default.bot};
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    word-wrap: break-word;
    max-width: 85%;
    padding: 4px 4px;
    margin-bottom: 10px;
  }
`;

export const MessageButtons = styled(Button)`
  && {
    word-wrap: break-word;
    background-color: ${(props) =>
    props.variant === "outlined"
      ? theme.palette.default.buttonSecondaryColor
      : theme.palette.default.buttonPrimaryColor};
    color: ${(props) =>
    props.variant === "outlined"
      ? theme.palette.default.buttonPrimaryColor
      : theme.palette.default.buttonSecondaryColor};
    border: 1px solid ${theme.palette.default.buttonPrimaryColor};
    border-radius: 99px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 1rem;
    gap: 8px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
`;

export const WidgetInputBoxWrap = styled(Box) <StyledProps>`
  && {
    height: 10%;
    max-height: 44px;
    min-height: 44px;
    width: 100%;
    background-color: ${({ theme }) =>
    theme?.components?.user_input.normal.background.color};
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 1rem rgba(0, 0, 0, 0.08);

    pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  }
`;

export const MessageInputField = styled(TextField)`
  && {
    height: 40px;
    background-color: ${({ theme }) =>
    theme?.components?.chat_wall.background.color};
    width: 100%;
    font-family: ${({ theme }) => theme?.font?.primary.family};
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    color: #7e8392;
    padding: 4px 8px 4px 1rem;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    // justify-content:baseline;
  }
`;

export const WidgetCopyrightBox = styled(Box) <StyledProps>`
  && {
    height: 1.5rem;
    min-height: 1.5rem;
    max-height: 1.5rem;
    z-index: 1;
    /* padding: 4px 0; */
    background-color: ${({ theme }) =>
    theme?.components?.chat_wall.background.color};
    border-radius: 0px 0px 8px 8px;
    display: flex;
    justify-content: right;
    padding-right: 10px;
    align-items: center;
    font-family: ${({ theme }) => theme?.font?.primary.family};
    border-top: 1px solid
      ${({ theme }) => theme?.components?.primary_color_scheme["100"]};
    font-weight: 400;
    font-size: 10px;
    box-shadow: none;
    color: #7e8392;
    overflow: hidden;
  }

  span,
  a {
    font-family: ${({ theme }) => theme?.font?.primary.family};

    font-weight: 500;
    font-size: 10px;
    letter-spacing: 1px;
    /* text-transform: capitalize; */
    color: #7e8392;
    max-height: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
  }

  img {
    height: 15px;
    object-fit: cover;
  }
`;

const JumpAnimation = keyframes`

0% {
    bottom: 5%;
    opacity: 0;
  }
  50% {
    bottom: 10%;
    opacity: 0.4;
  }
  100% {
    bottom: 15%;
    opacity: 1;
  }
`;

export const JumpDivContainer = styled.div<StyledProps>`
  && {
    position: fixed;
    bottom: 15%;
    right: 5%;
    /* left: 10%; */
    /* right: auto; */
    /* width: 95%; */
    /* display: flex; */
    /* justify-content: center; */

    .MuiFab-root {
      width: 32px;
      height: 32px;
      min-height: 32px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${({ theme }) =>
    theme?.components?.bot_message?.background?.color};

      &:hover {
        background-color: ${({ theme }) =>
    theme?.components?.primary_color_scheme["100"]};
      }
    }

    .MuiSvgIcon-root {
      font-size: 1.5rem;
      color: ${({ theme }) => theme?.components?.user_input.normal.text.color};
    }
  }
`;

export const JumpDiv = styled.div<StyledProps>`
  && {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 4px;
    padding: 3px 10px;
    border-radius: 50px;

    background-color: ${({ theme }) => theme?.palette?.primary.main};
    opacity: 0.8;
    cursor: pointer;
    color: ${({ theme }) => theme?.font?.primary.color.main};
    font-family: ${({ theme }) => theme?.font?.primary.family};
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    animation: ${JumpAnimation} 0.2s 1 ease-in-out forwards;

    /* p {
      opacity: 1;
    } */
  }
`;

const loadingFade = keyframes`
0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
`;

export const StyledTyping = styled.div<StyledProps>`
  background-color: ${({ userMessage, theme }) =>
    userMessage
      ? theme?.components?.user_message?.background?.color
      : theme?.components?.bot_message?.background?.color};
  padding: 4px;
  border-radius: 5px;
  && {
  }

  .typing__dot {
    float: left;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background: ${({ userMessage, theme }) =>
    userMessage
      ? theme?.components?.user_message.text.color
      : theme?.components?.bot_message.text.color};
    border-radius: 50%;
    opacity: 0;
    animation: ${loadingFade} 0.5s infinite;
  }

  .typing__dot:nth-of-type(1) {
    animation-delay: 0s;
  }

  .typing__dot:nth-of-type(2) {
    animation-delay: 0.2s;
  }

  .typing__dot:nth-of-type(3) {
    animation-delay: 0.4s;
  }
`;
export const StyledDrawerHeading = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    padding: 10px 16px 8px 14px;
    align-items: center;
    .drawerHeading {
      font-family: ${({ theme }) => theme?.font?.primary.family};
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 1px;
    }
  }
`;
export const StyledDrawerHeader = styled.div`
  && {
    position: absolute;
    border-radius: 12px 12px 0 0;
    visibility: visible;
    right: 0;
    left: 0;
    overflow: hidden;
    background-color: ${({ theme }) => {
    return theme?.components?.header.background.color;
  }};
    p {
      color: ${({ theme }) => {
    return theme?.components?.header.text.color;
  }};
      font-family: ${({ theme }) => theme?.font?.primary.family};
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 1px;
    }
    svg {
      color: ${({ theme }) => {
    return theme?.components?.header.text.color;
  }};
      font-size: 22px;
    }
  }
`;

export const StyledTriggerDrawerButton = styled.button`
  && {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row !important;
    align-items: center !important;

    background-color: ${({ theme }) => {
    return theme?.components?.header.background.color;
  }};
    color: ${(props) => theme.palette.default.white};
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 42px;
    margin: 0;
    font-weight: 400;
    font-size: 1rem;
    line-height: 21px;
  }
`;
export const StyledLoadingButton = styled(LoadingButton)`
  && {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    background-color: ${(props) => theme.palette.primary.main};
    color: ${(props) => theme.palette.default.white};
    border: 1px solid ${theme.palette.primary.main};
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 2.5rem;
    margin: 6px 0;
    font-weight: 400;
    font-size: 1rem;
    line-height: 21px;
  }
`;
export const StyledRadioInputBox = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.9rem;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
  }
`;
export const StyledDateChooseButton = styled(Button) <StyledProps>`
  && {
    cursor: pointer;

    align-items: center !important;
    font-size: 14px;
    background-color: ${({ theme, selected }) =>
    selected ? theme.palette.primary.main : theme.palette.default.white};
    color: ${({ theme, selected }) =>
    selected ? theme.palette.default.white : theme.palette.primary.main};
    border: 1px solid ${theme.palette.primary.main};
    border-radius: 99px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 1rem;
    gap: 8px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-transform: none !important;
  }
`;
export const StyledTimeChooseButton = styled(Button) <StyledProps>`
  && {
    cursor: pointer;
    background-color: white;
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    font-size: 12px;
    background-color: ${({ theme, selected }) =>
    selected ? theme.palette.primary.main : theme.palette.default.white};
    color: ${({ theme, selected }) =>
    selected ? theme.palette.default.white : theme.palette.primary.main};
    border: 1px solid ${theme.palette.primary.main};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 1rem;
    gap: 8px;
    font-family: ${theme.typography.fontFamily};
    /* width: 100%; */
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
`;
export const OptionFieldLabel = styled.p`
  && {
    font-family: ${({ theme }) => theme?.font?.primary.family};
    color: #7e8392;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    gap: 1rem;

    .MuiSvgIcon-root {
      font-size: 1.3rem;
      color: #7e8392;
    }
  }
`;

export const WidgetSliderBox = styled.div`
  && {
    background-color: transparent;
    background-color: ${theme.palette.secondary.main};
    border-radius: 6px 6px 6px 6px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 4px 4px 2px;
    margin: 4px 0;
  }
  .btnWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
  }
  .title {
    padding: 2px 6px 0px;
    padding-left: 18px;
    font-size: 0.9rem;
    font-weight: 500;
    text-overflow: ellipsis;
    font-family: ${({ theme }) =>
    theme?.components?.user_input.normal.text.family};

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .description {
    padding: 0px 6px;
    padding-left: 14px;
    font-size: 0.9rem;
    text-overflow: ellipsis;
    font-family: ${({ theme }) =>
    theme?.components?.user_input.normal.text.family};

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
  }
  img {
    width: 100%;
    height: 150px;
    border-radius: 4px;
    min-height: 150px;
    max-height: 150px;
    overflow: hidden;
    border: none;
    object-fit: contain;
  }
  video {
    width: 100%;
    height: 150px;
    overflow: hidden;
  }
`;

export const StyledAlertBox = styled.div<StyledProps>`
  && {
    z-index: 1;
    background-color: ${theme.palette?.default?.success};
    width: 100%;
    height: 28px;
    font-size: 13px;
    text-overflow: ellipsis;
    font-family: ${({ theme }) =>
    theme?.components?.user_input.normal.text.family};
    color: ${theme.palette?.default?.white};
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.5px;
    font-weight: 500;
    padding: 2px 8px 2px 16px;

    text-overflow: ellipsis; /* enables ellipsis */
    white-space: nowrap; /* keeps the text in a single line */
    overflow: hidden;
  }
`;

//* Buttons *//

export const PrimaryButton = styled(LoadingButton)`
  && {
    outline: none;
    text-transform: capitalize;
    text-align: center;

    padding: 6px 12px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-family: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.text?.family;
  }};

    border-radius: 6px;
    border: 1px solid
      ${({ theme }) => {
    return theme?.components?.button_background?.main;
  }} !important;

    background-color: ${({ theme }) => {
    return theme?.components?.button_background?.main;
  }};
    color: ${({ theme }) => {
    return theme?.components?.button_text_color?.main;
  }};

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => {
    return theme?.components?.button_text_color?.main;
  }};
      width: 100%;
      svg {
        margin-right: 5px;
        max-width: 13px;
      }
    }

    &:hover {
      background-color: ${({ theme }) => {
    return (
      theme?.components?.hover_background?.main ||
      theme?.components?.header.background.color
    );
  }};
      color: ${({ theme }) => {
    return (
      theme?.components?.button_hover_text?.main ||
      theme?.components?.header.text.color
    );
  }};
      box-shadow: 0px 1px 4px #999;
      a {
        color: ${({ theme }) => {
    return (
      theme?.components?.button_hover_text?.main ||
      theme?.components?.header.text.color
    );
  }};
        width: 100%;
      }
    }

    &:disabled {
      background-color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.background
      ?.color;
  }};
      color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};

      a {
        color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};
        width: 100%;
      }
    }
  }
`;

export const OutlineButton = styled(LoadingButton)`
  && {
    outline: none;
    text-transform: none;
    text-align: center;

    padding: 6px 12px;
    font-size: 14px;
    font-weight: 500;
    font-family: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.text?.family;
  }};

    border-radius: 6px;
    border: 1px solid
      ${({ theme }) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }} !important;

    background-color: transparent;
    color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }};

    a {
      color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }};
      width: 100%;
      font-family: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.text?.family;
  }} !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Poppins !important;
      gap: 12px;
      svg {
        font-size: 17px;
      }
    }

    &:hover {
      background-color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.background
      ?.color;
  }};
      color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};

      a {
        color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};
        width: 100%;
      }
    }
    &:disabled {
      background-color: transparent;
      color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }};

      a {
        color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }};
        width: 100%;
      }
    }
  }
`;

export const GreyButton = styled(LoadingButton)`
  && {
    outline: none;
    text-transform: capitalize;
    text-align: center;

    padding: 6px 12px;
    font-size: 14px;
    font-weight: 500;
    font-family: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.text?.family;
  }};

    border-radius: 6px;
    border: 1px solid ${theme.palette.default.placeholder} !important;

    background-color: transparent;
    color: ${theme.palette.default.darkGrey};

    a {
      color: ${theme.palette.default.darkGrey};
      width: 100%;
    }

    &:hover {
      background-color: ${theme.palette.default.placeholder};
      color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};

      a {
        color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};
        width: 100%;
      }
    }
  }
`;

export const TextButton = styled(LoadingButton)`
  && {
    outline: none;
    text-transform: capitalize;
    text-align: center;

    padding: 2px 8px;
    font-size: 14px;
    font-weight: 500;
    font-family: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.text?.family;
  }};

    border-radius: 6px;
    border: none;

    background-color: transparent;
    color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.background?.color;
  }};

    a {
      color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }};
      width: 100%;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.background
      ?.color;
  }};

      a {
        color: ${({ theme }) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};
        width: 100%;
      }
    }
  }
`;

export const StyledBottomDrawerBox = styled(motion.div as any)`
  && {
    width: 100%;
    min-width: 20%;
    background-color: white;
    overflow: hidden;
    position: absolute;
    z-index: 9999;
    bottom: 0px;
    left: 0px;
    border-radius: 8px 8px 0 0;
    padding: 1rem 0.5rem 1rem;
    display: flex;
  }
`;

export const StyledLanguageBox = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 0.7rem;
    z-index: 92333923;
    ::-webkit-scrollbar {
      /* display: none; */

      width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .title {
      font-family: ${({ theme }) =>
    theme?.components?.bot_message?.text?.family};
      color: ${({ theme }) => theme?.components?.bot_message?.text?.color};
      font-weight: 400;

      font-size: 15px;
    }
    li {
      font-family: ${({ theme }) =>
    theme?.components?.bot_message?.text?.family};
      color: ${({ theme }) => theme?.components?.bot_message?.text?.color};
      font-weight: 400;
      min-height: 30px;
      font-size: 15px;
      padding: 4px 12px;
      margin-right: 1px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      &:hover {
        background-color: ${({ theme }) => {
    return theme?.components?.chat_wall.background.color;
  }};
      }
    }
  }
`;

export const StyledRatingDrawerContentBox = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
    overflow-x: hidden;
    overflow-y: auto;
    .title {
      font-family: ${({ theme }) => theme?.font?.primary.family};
      color: #7e8392;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 1px;

      text-align: center;
      width: 90%;
      margin-top: 1rem;
    }

    .ratingBox {
      width: 100%;
      height: 32px;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      margin: 0.5rem 0 0.8rem;
      .ratingText {
        font-family: ${({ theme }) => theme?.font?.primary.family};
        color: #282828;
        font-weight: 400;
        font-size: 1rem;
        letter-spacing: 1px;
      }
    }

    .actionBtnBox {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;

      .skipBtn {
        position: absolute;
        right: 0px;
        /* top: 40%; */
        align-self: flex-end !important;
      }
    }

    /* -ms-overflow-style: none; IE and Edge */
    /* scrollbar-width: none; Firefox */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    /* display: none; */

    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const StyledRatingDrawerTextContentBox = styled.div`
  && {
    width: 100%;
    padding: 0px 0px 1rem;
    display: flex;
    flex-direction: column;

    gap: 1rem;

    .title {
      font-family: ${({ theme }) => theme?.font?.primary.family};
      font-weight: 500;
      font-size: 0.95rem;
      letter-spacing: 0.5px;
      color: ${theme.palette.default.text};
      text-align: center;
      width: 100%;
    }

    .ratingBox {
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;

      .MuiSvgIcon-root {
        color: #282828 !important;
      }

      .ratingText {
        font-family: ${({ theme }) => theme?.font?.primary.family};
        color: #282828;
        font-weight: 500;
        font-size: 15px;
        letter-spacing: 1px;
      }
    }

    .captionText {
      width: 100%;
      text-align: center;
      color: ${theme.palette.default.text};
      font-size: 13px;
    }

    .actionBtnBox {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;

      .skipBtn {
        position: absolute;
        right: 0px;
        /* top: 40%; */
        align-self: flex-end !important;
      }
    }

    /* -ms-overflow-style: none; IE and Edge */
    /* scrollbar-width: none; Firefox */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    /* display: none; */

    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const StyledAlertContainerText = styled.div`
  && {
    margin: 8px 0;
    max-width: 90%;
    border-radius: 4px;
    padding: 4px 14px 4px 14px;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-size: 13px;
    font-weight: 500;

    font-family: ${({ theme }) => theme?.components?.bot_message?.text?.family};
    color: ${({ theme }) => theme?.components?.user_message?.text?.color};
    background-color: ${({ theme }) =>
    theme?.components?.user_message?.background?.color};
  }
`;
export const StyledFileContainerBox = styled.div`
  && {
    width: 100%;
    height: 68px;
    border-radius: 4px;
    padding: 8px 8px 8px 12px;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
    font-size: 14px;
    font-weight: 500;

    font-family: ${({ theme }) => theme?.components?.bot_message?.text?.family};

    color: ${({ theme }) => theme?.components?.user_message?.background?.color};
    background-color: transparent;
    border: 1px solid
      ${({ theme }) => theme?.components?.user_message?.background?.color};
    &:hover {
      background-color: ${({ theme }) =>
    theme?.components?.user_message?.background?.color};
      color: ${({ theme }) => theme?.components?.user_message?.text?.color};
      svg {
        color: ${({ theme }) => theme?.components?.user_message?.text?.color};
      }
    }
    .fileNameBox {
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
    }

    .fileActionBox {
      .MuiIconButton-root {
        svg {
          font-size: 30px;
        }
      }
    }
  }
`;
export const StyledDrawerBox = styled(m.div as any)`
  && {
    position: absolute;
    bottom: 44.5px;
    z-index: 999999999999;

    background-color: red;
    background-color: white;
    width: 100%;
    max-height: 430px;
    height: 320px;
    padding: 0rem 0rem 0.5rem;
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: overlay;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.08);

    /* :before {
  content: ' ';
  content: " ";
  position: absolute;
  left: 4px;
  top: -10px;
  border-top: none;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  border-bottom: 12px solid ${({ theme }) => {
    return theme?.components?.chat_wall.background.color;
  }};
  border-bottom: 10px solid red;
  border-radius: 4px;
} */
    .drawerHeader {
      padding: 0.6rem 1rem;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-weight: 500;
      letter-spacing: 0.5px;
      font-size: 15px;
      color: #7e8392;
      position: sticky;
      top: 0;
      z-index: 10;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        svg {
          font-size: 22px;
        }
      }

      .MuiIconButton-root {
        font-size: 16px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 22px;
        }
      }
    }
    .drawerContentBox {
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      padding: 0.2rem 1rem 0.5rem;
    }

    .drawerInfoText {
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 2px 4px;
      font-family: ${({ theme }) =>
    theme?.components?.bot_message?.text?.family};
      font-weight: 400;
      font-size: ${({ theme }) => theme?.components?.bot_message?.text?.size};
      line-height: 24px;

      margin-bottom: 1rem;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    /* -ms-overflow-style: none; IE and Edge */
    /* scrollbar-width: none; Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      /* display: none; */

      width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export const Label = styled.label`
  && {
    font-family: ${({ theme }) => theme?.components?.bot_message?.text?.family};
    font-weight: 500;
    font-size: 13.5px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;
export const StyledFieldWrapperBox = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    margin-bottom: 0.35rem;
    position: relative;
  }
`;

export const StyledQuickMenuWrapper = styled.div`
  && {
    .MuiAccordion-root {
      box-shadow: none;
      .MuiAccordionSummary-root {
        min-height: 44px;
        box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
      }
      .MuiAccordionSummary-content {
        align-items: center;
        gap: 8px;
      }
      .Mui-expanded {
        margin: 0;
      }
      .MuiCollapse-root {
        overflow: hidden;
        overflow-y: overlay;
        max-height: 190px;
      }

      .MuiAccordionDetails-root {
        background-color: #fdfcfc;
        /* background-color: #24d31e; */
        width: 100%;
        padding: 0.15rem 0;
        /* padding: 0; */
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        overflow: overlay;
        overflow-x: hidden;
        height: auto;
        /* gap: 6px;  */
      }
    }
    .bodyContent {
    }

    .accordianTitle {
      font-family: ${({ theme }) =>
    theme?.components?.bot_message?.text?.family};
      font-weight: 500;
      font-size: 0.85rem;
      /* line-height: 1.2rem; */
      text-transform: capitalize;
      /* color: ${({ theme }) =>
    theme?.components?.bot_message?.text?.color}; */
      /* opacity: 0.9; */
      color: #606060;
    }

    /* width: 100%;
    padding: 0.5rem 0.4rem;
    background-color: #fdfcfc;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: overlay;
    overflow-x: hidden;
    height: auto;
    gap: 6px; */
    .singleCard {
      width: 33.2%;
      /* max-width: 33%; */
      /* flex: 1 1 100%;   */
      /* flex-basis: 100%;   */
      /* max-width: 130px;
      flex: 1 0 28%; */
      height: 90px;
      /* margin: 6px; */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 6px;
      /* border-radius: 8px; */
      overflow: hidden;
      cursor: pointer;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border: 1px solid rgba(0, 0, 0, 0.08);
      border: 1px solid transparent;
      text-align: center;
      padding: 6px 2px 2px;
      border: 1px solid rgba(0, 0, 0, 0.08);

      &:hover {
        transform: scale(1.01);
        border: 1px solid rgba(0, 0, 0, 0.08);
      }
      .cardImage {
        width: 52px;
        height: 44px;
        object-fit: cover;
        border-radius: 4px;
      }

      .buttonTitle {
        font-family: ${({ theme }) =>
    theme?.components?.bot_message?.text?.family};
        color: ${({ theme }) => theme?.components?.bot_message?.text?.color};
        opacity: 0.8;
        font-weight: 500;
        font-size: 12.5px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .singleRow {
      width: 98%;
      height: 44px;
      min-height: 44px;
      max-height: 44px;
      display: flex;
      align-items: center;
      justify-content: start;
      padding-left: 6px;
      gap: 1rem;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border: 1px solid transparent;

      &:hover {
        transform: scale(1.01);
        border: 1px solid rgba(0, 0, 0, 0.06);
      }
      .rowImage {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 4px;
      }

      .buttonTitle {
        width: 100%;
        font-family: ${({ theme }) =>
    theme?.components?.bot_message?.text?.family};
        color: ${({ theme }) => theme?.components?.bot_message?.text?.color};
        opacity: 0.8;
        font-weight: 500;
        font-size: 13px;
      }
    }
  }
`;

export const StyledNotificationBlockWrapper = styled.div`
  && {
    .notificationIcon {
      svg {
        font-size: 19px;
        color: ${({ theme }) => {
    return theme?.components?.header.text.color;
  }};
      }
    }

    .notificationListWrapper {
      height: 100%;
      padding: 0.5rem 1rem 6px;
      position: relative;
      background-color: rgba(255, 255, 255, 0.7);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      gap: 3px;
      border-radius: 4px;
      overflow: hidden;
      flex: 1;

      .singleRow {
        background-color: rgba(0, 0, 0, 0.03);
        width: 100%;
        padding: 4px;
        height: 96px;
        display: flex;
        justify-content: space-between;
        border-radius: 6px;
        color: black;
        cursor: pointer;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
        border: 1px solid transparent;

        &:hover {
          transform: scale(1.01);
          border: 1px solid rgba(0, 0, 0, 0.06);
        }
        .rowLeftBox {
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          height: 100%;
          min-width: 80px;
          padding: 2px;
          img {
            height: 72px;
            width: 72px;
            object-fit: cover;
            border-radius: 4px;
          }
        }

        .rowRightBox {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 4px 6px 2px;
          overflow: hidden;

          .rowRightTop {
            display: flex;
            width: 100%;
            overflow: hidden;
            min-height: 20px;
            font-family: ${({ theme }) =>
    theme?.components?.bot_message?.text?.family};
            font-weight: 500;
            font-size: 0.85rem;
            line-height: 1.2rem;
            text-transform: capitalize;
            color: ${({ theme }) =>
    theme?.components?.bot_message?.text?.color};

            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .rowRightMiddle {
            display: flex;
            justify-content: left;
            align-items: center;
            width: 100%;
            max-height: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-family: ${({ theme }) =>
    theme?.components?.bot_message?.text?.family};
            font-size: 0.75rem;
            line-height: 1.2rem;
            color: ${theme.palette.default.darkGrey};
          }
          .rowRightBottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            overflow: hidden;

            font-size: 0.75rem;
            line-height: 1.2rem;
            color: ${theme.palette.default.darkGrey};
            .footerText {
              font-family: ${({ theme }) =>
    theme?.components?.bot_message?.text?.family};
              font-size: 0.75rem;
              line-height: 1.2rem;
              color: ${theme.palette.default.darkGrey};
            }

            button {
              color: dodgerblue;
            }
          }
        }
      }
    }
    .staticButton {
      button {
        color: #7e8392;
        min-height: 36px;
        max-height: 36px;
      }
      position: -webkit-sticky;
      position: sticky;
      z-index: 999;
      bottom: 0rem;
      border-radius: 10px;
      min-height: 36px;
      max-height: 36px;
      width: 100%;
      margin-top: auto;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
`;

export const StyledResendBoxWrap = styled.div`
  && {
    width: 200px;
    height: 40px;
    color: ${({ theme }) => theme?.components?.user_input.focus.text.color};
    font-size: 14.5px;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 4px 1rem;
    position: absolute;
    bottom: 24px;
    right: 45px;
    button {
      margin-left: auto;
      text-decoration: underline;
      font-size: 12px;
      &:hover {
        background-color: transparent;
        text-decoration: none;
      }
    }
    .timerWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: ${theme.palette.default.placeholder};
      margin-left: auto;
      min-width: 50px;
      svg {
        margin-right: 5px;
        width: 12px;
        margin-top: -2px;
      }
    }
  }
`;

//inputs
export const ActionBoxWrapper = styled.div<StyledProps>`
  display: flex;
  justify-content: flex-start;
  padding: 6px 12px;
  background-color: white;
  button {
    padding: 2px 10px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
  }
`;

// Quick Menu Header
export const QuickMenuHeaderWrapper = styled.div`
  & {
    .notificationListWrapper {
      display: flex;
      flex-wrap: wrap;
      padding-right: 6px;
      ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: #cacaca;
      }
      ::-webkit-scrollbar {
        width: 4px;
        background-color: #dfdfdf;
      }
    }
    .singleRow {
      width: 98%;
      height: 44px;
      min-height: 44px;
      max-height: 44px;
      display: flex;
      align-items: center;
      justify-content: start;
      padding-left: 6px;
      gap: 1rem;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border: 1px solid transparent;

      &:hover {
        transform: scale(1.01);
        border: 1px solid rgba(0, 0, 0, 0.06);
      }
      .rowImage {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 4px;
      }

      .buttonTitle {
        width: 100%;
        font-family: ${({ theme }) =>
    theme?.components?.bot_message?.text?.family};
        color: ${({ theme }) => theme?.components?.bot_message?.text?.color};
        opacity: 0.8;
        font-weight: 500;
        font-size: 13px;
      }
    }

    .singleCard {
      width: 33.2%;
      /* max-width: 33%; */
      /* flex: 1 1 100%;   */
      /* flex-basis: 100%;   */
      /* max-width: 130px;
      flex: 1 0 28%; */
      height: 90px;
      /* margin: 6px; */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 6px;
      /* border-radius: 8px; */
      overflow: hidden;
      cursor: pointer;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border: 1px solid rgba(0, 0, 0, 0.08);
      border: 1px solid transparent;
      text-align: center;
      padding: 6px 2px 2px;
      border: 1px solid rgba(0, 0, 0, 0.08);

      &:hover {
        transform: scale(1.01);
        border: 1px solid rgba(0, 0, 0, 0.08);
      }
      .cardImage {
        width: 52px;
        height: 44px;
        object-fit: cover;
        border-radius: 4px;
      }

      .buttonTitle {
        font-family: ${({ theme }) =>
    theme?.components?.bot_message?.text?.family};
        color: ${({ theme }) => theme?.components?.bot_message?.text?.color};
        opacity: 0.8;
        font-weight: 500;
        font-size: 12.5px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
`;
