import React from "react";
import { useAppSelector } from "../../../Store";
import { useBotConfigStore } from "../../../Store/Slices/ChatbotSlices/BotConfigSlice";
import { WidgetMainChat, WidgetMainContent } from "../../Styles/StyledScreens";
import AppRenderBannerCarousel from "../Features/AppRenderBannerCarousel";
import AppRenderQuickMenu from "../Features/AppRenderQuickMenu";
import { MessagesScreen } from "../../screens";
import { useSelector } from "react-redux";
import { botInfoStore } from "../../../Store/Slices/socket/BotInfoSlice";

interface Props {
  children?: React.ReactNode;
}

const AppChatScreenWrapper: React.FC<Props> = () => {
  const { botConfig } = useAppSelector(useBotConfigStore);
  const messagesEndRef = React.useRef<any>(null);
  const { isBotSetToFullScreen } = useSelector(botInfoStore);

  return (
    <WidgetMainContent
      ref={messagesEndRef}
      style={
        botConfig?.config?.background_image?.source
          ? {
              backgroundImage:
                "url(" + botConfig?.config?.background_image?.source + ")",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }
          : {}
      }
    >
      <WidgetMainChat
        followButtonClassName="scrollToBottomDefaultButton"
        className="WidgetMainChat"
        scrollViewClassName="styledList"
        initialScrollBehavior="auto"
        behavior="auto"
        mode={isBotSetToFullScreen ? "top" : "end"}
        debug={false}
      >
        <AppRenderBannerCarousel />
        <MessagesScreen />
      </WidgetMainChat>
      {/* <AppRenderQuickMenu /> */}
    </WidgetMainContent>
  );
};

export default AppChatScreenWrapper;
