import { Dialog } from "@mui/material";
import React from "react";
import styled from "styled-components";
interface Props {
  isShowing: boolean;
  onClose: any;
  innerRef?: any;
  divStyle?: any;
  children?: any;
  [otherProps: string]: any;
}

const AppModel: React.FC<Props> = ({
  isShowing,
  onClose,
  innerRef,
  divStyle,
  children,
  ...otherProps
}) => {
  if (isShowing) {
    return (
      <StyledDialog open={isShowing} onClose={onClose} {...otherProps}>
        <StyledModelBox style={divStyle} {...otherProps}>
          {children}
        </StyledModelBox>
      </StyledDialog>
    );
  }

  return null;
};

export default AppModel;

const StyledDialog = styled(Dialog)`
  && {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    small {
      cursor: default;
      font-family: Poppins;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 30px;
      color: #7e8392;
    }

    .MuiDialogContent-root {
      padding: 1rem 1.5rem 0rem;
    }
  }
`;
const StyledModelBox = styled.div`
  && {
    padding: 0.7rem 0.7rem;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    .title {
      font-family: ${({ theme }) =>
        theme?.components?.bot_message?.text?.family};
      font-weight: 500;
      font-size: ${({ theme }) => theme?.components?.bot_message?.text?.size};
      font-size: 16px;
      color: ${({ theme }) => theme?.components?.bot_message?.text?.color};
    }
    .content {
      font-family: ${({ theme }) =>
        theme?.components?.bot_message?.text?.family};
      font-weight: 400;
      font-size: ${({ theme }) => theme?.components?.bot_message?.text?.size};
      font-size: 15px;
      color: ${({ theme }) => theme?.components?.bot_message?.text?.color};
      margin: 0.1rem 0;
    }
    .actionBtn {
      display: flex;
      flex-direction: row;
      justify-content: right;
      gap: 1.2rem;
    }
  }
`;
